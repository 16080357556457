<template>
  <div>
    <section class="mb-4 d-flex align-center">
      <h3 class="text-h5 text-uppercase font-weight-extrabold">
        Bets History
      </h3>
      <v-spacer />
      <v-text-field
        v-model="filters.steamid"
        solo dense hide-details flat
        class="rounded-0 text-caption mr-2"
        placeholder="Search by steamid"
        background-color="grey800"
      />
      <v-btn
        color="grey300"
        tile small class="unwidth"
        :ripple="false" :loading="loading"
        @click="fetchApiData"
      >
        <fai :icon="['fas','sync']" class="grey100--text" />
      </v-btn>
    </section>

    <v-expansion-panels
      tag="section" class="mb-4 grey800" accordion flat
      tile
    >
      <v-expansion-panel>
        <v-expansion-panel-header color="grey800">
          Filters
        </v-expansion-panel-header>
        <v-expansion-panel-content color="grey800">
          <v-form @submit.prevent="fetchApiData">
            <v-row>
              <v-col cols="12">
                <v-menu
                  v-model="datepicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y right min-width="290px"
                >
                  <template #activator="{ on }">
                    <div class="d-flex">
                      <v-btn
                        color="grey600" class="mr-2"
                        tile large :ripple="false"
                        v-on="on"
                      >
                        {{ filters.range[0] }}
                        <span class="text--secondary mx-2">&ndash;</span>
                        {{ filters.range[1] }}
                      </v-btn>

                      <v-btn
                        large color="grey500" tile class="px-3 mr-2 unwidth"
                        @click.stop="setDateRangePreset('day')"
                      >
                        1D
                      </v-btn>
                      <v-btn
                        large color="grey500" tile class="px-3 mr-2 unwidth"
                        @click.stop="setDateRangePreset('week')"
                      >
                        7D
                      </v-btn>
                      <v-btn
                        large color="grey500" tile class="px-3 mr-2 unwidth"
                        @click.stop="setDateRangePreset('month')"
                      >
                        30D
                      </v-btn>
                      <v-btn
                        large color="grey500" tile class="px-3 mr-2 unwidth"
                        @click.stop="setDateRangePreset('6months')"
                      >
                        6M
                      </v-btn>
                      <v-btn
                        large color="grey500" tile class="px-3 unwidth"
                        @click.stop="setDateRangePreset('year')"
                      >
                        12M
                      </v-btn>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="filters.range"
                    color="primary500"
                    scrollable
                    no-title
                    show-adjacent-months
                    range
                  />
                </v-menu>
              </v-col>

              <v-col cols="12" md="4">
                <v-combobox
                  v-model="filters.games"
                  :items="GAMES"
                  solo flat small-chips multiple
                  clearable
                  class="rounded-0"
                  background-color="grey600"
                  placeholder="You can add custom ones"
                  label="Types"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="filters.statuses"
                  :items="STATUSES"
                  solo flat small-chips multiple
                  clearable
                  class="rounded-0"
                  background-color="grey600"
                  placeholder="Search statuses"
                  label="Statuses"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <section>
      <v-data-table
        class="rounded-0 data-table"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions: [5, 10, 25]}"
        :server-items-length="total"
        :loading="loading"
        :expanded="expanded"
        @pagination="onPagination"
      >
        <template #item.game="{ value, item }">
          <h4 class="text-body-2 text-capitalize font-weight-bold" v-text="value" />
          <h5 class="text-caption blue400--text lh-1" v-text="item.userId" />
        </template>
        <template #item.status="{ item, value }">
          <v-progress-circular
            v-if="betStatuses[value] === 'pending'"
            color="blue300"
            indeterminate size="12" width="2" class="mr-1"
          />
          <span class="text-capitalize text-caption blue300--text">{{ betStatuses[value] }}</span>
        </template>

        <template #item.amount="{ value, item }">
          <span v-if="item.game === 'coinflip'">
            ${{ value | toScrap }}
          </span>
          <scrap v-else :class="{'faded-5': betStatuses[item.status] === 'refunded'}" :amount="value" />
        </template>
        <template #item.won="{ value, item }">
          <span v-if="!value" class="text--disabled">&mdash;</span>
          <b v-else class="green500--text">
            <span v-if="item.game === 'coinflip'">$</span>
            <span>{{ value | toScrap }}</span>
          </b>
        </template>
        <template #item.createdAt="{ value }">
          <v-tooltip top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ value | relativeTime('twitter') }}</span>
            </template>
            <span>{{ value | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
        </template>
        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'info-square']"
            class="text-body-1 mt-1 blue500--text link"
            @click="onExpandItem(item)"
          />
        </template>

        <!-- transaction info -->
        <template #expanded-item="{ headers: head, item }">
          <td :colspan="head.length" class="grey800 py-3 data-detail">
            <v-expansion-panels accordion flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header color="grey600">
                  Full bet object
                </v-expansion-panel-header>
                <v-expansion-panel-content color="grey600">
                  <pre v-text="item" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </td>
        </template>
      </v-data-table>
    </section>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { debounce } from '@/utils'
import { BET_STATUS } from '@/utils/constants'

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      expanded: [],
      datepicker: null,
      filters: {
        steamid: '',
        games: [],
        statuses: [],
        range: [
          DateTime.now().minus({ days: 7 }).toISODate(),
          DateTime.now().toISODate(),
        ],
      },
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'Game', value: 'game', align: 'start' },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Amount', value: 'amount', align: 'end' },
        { text: 'Won', value: 'won', align: 'end' },
        { text: 'Time', value: 'createdAt', align: 'end' },
        { text: '', value: 'actions', width: 8 },
      ],
      entries: [],
    }
  },
  computed: {
    betStatuses() {
      return Object.entries(BET_STATUS).reduce((acc, [k, v]) => {
        acc[v] = k
        return acc
      }, {})
    },
    STATUSES() {
      return Object.entries(BET_STATUS).map(([text, value]) => ({ text, value }))
    },
    GAMES() {
      return ['spinners', 'wheel', 'dice', 'cases', 'coinflip', 'caseBattles']
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    filters: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    payload: {
      handler(payload) {
        if (payload?.steamid) {
          this.filters.steamid = payload.steamid
        }
      },
      immediate: true,
    },
  },
  methods: {
    fetchApiData: debounce(async function fetchApiData() {
      this.loading = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        // TODO move to store
        const { entries, total } = await this.$socket.request('admin.user.bets', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          filter: {
            ...this.filters,
            dateStart: this.filters.range[0],
            dateEnd: this.filters.range[1],
          },
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    }, 500),
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item.id === expandedItem?.id) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    onPagination() {
      this.expanded = []
    },
    setDateRangePreset(preset) {
      switch (preset) {
        case 'day':
          this.filters.range = [DateTime.now().toISODate(), DateTime.now().toISODate()]
          break
        case 'week':
          this.filters.range = [DateTime.now().minus({ days: 7 }).toISODate(), DateTime.now().toISODate()]
          break
        case 'month':
          this.filters.range = [DateTime.now().minus({ months: 1 }).toISODate(), DateTime.now().toISODate()]
          break
        case '6months':
          this.filters.range = [DateTime.now().minus({ months: 6 }).toISODate(), DateTime.now().toISODate()]
          break
        case 'year':
          this.filters.range = [DateTime.now().minus({ years: 1 }).toISODate(), DateTime.now().toISODate()]
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }

  .v-data-table__expanded__row {
    td {
      border-bottom: 0 !important;
    }
  }

  .transaction-detail-container {

  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
